import { observer } from 'mobx-react-lite';
import './LegalNotice.scss';
import useRootStore from 'hooks/useRootStore';
import { EXTERNAL_URLS } from 'constants/routes';

const COMPANY_DEFAULT: CompanyEntity = {
  id: '',
  email: '',
  phoneNumber: '',
  bic: '',
  iban: '',
  name: 'JUSSIEU secours FRANCE',
  address: '13 rue de la Tuilerie, 37550 SAINT AVERTIN',
  equity: '37 000',
  rcsNumber: '484628995',
  representativeJobTitle: 'Président',
  representativeName: 'Pascal BARTHES',
  status: 'Société par Actions Simplifiée',
};

function LegalNotice() {
  const { globalStorage } = useRootStore();
  const { company } = globalStorage.getValues();
  const companyLegal = company || COMPANY_DEFAULT;

  return (
    <section className="legal">
      <div className="legal__banner">
        <h1 className="page-title page-title--legal">Mentions légales</h1>
      </div>
      <div className="container container--legal">
        <p className="legal__intro">
          La poursuite de la navigation sur ce site vaut acceptation sans
          réserve des dispositions et conditions d'utilisation qui suivent. La
          version actuellement en ligne de ces conditions d'utilisation est la
          seule opposable pendant toute la durée d'utilisation du site et
          jusqu'à ce qu'une nouvelle version la remplace.
        </p>
        <div className="legal__list">
          <div className="legal__item">
            <div className="legal__title">Article 1 - Informations légales</div>
            <p className="legal__text">1.1. - Site (ci-après « le site ») :</p>
            <p className="legal__text">www.jussieu-secours.fr</p>

            <p className="legal__text">
              1.2 - Éditeur (ci-après « l'éditeur ») :
            </p>
            {companyLegal && (
              <p data-testid="company-legal-info" className="legal__text">
                {companyLegal.name}, {companyLegal.status} au capital de{' '}
                <span className="number number--legal">
                  {companyLegal.equity}
                </span>{' '}
                euros, dont le siège social est situé{' '}
                <span className="number number--legal">
                  {companyLegal.address}
                </span>
                , immatriculée au RCS sous le numéro{' '}
                <span className="number number--legal">
                  {companyLegal.rcsNumber}
                </span>{' '}
                et représentée par {companyLegal.representativeName}, en sa
                qualité de{' '}
                {companyLegal.representativeJobTitle || 'président(e)'}.
              </p>
            )}
            <div className="legal__text">
              Directeur de la publication : Pascal BARTHES.
            </div>
            <div className="legal__text">1.3 - Conception et réalisation :</div>
            <div className="legal__text">
              Breizh Digital – Start In Block, Société par Actions Simplifiée au
              capital de <span className="number number--legal">3 000</span>{' '}
              euros, dont le siège social est situé 7, La Force aux Aubins,{' '}
              <span className="number number--legal">35590</span> SAINT-GILLES,
              immatriculée au RCS sous le numéro{' '}
              <span className="number number--legal">831155387</span> et
              représentée par Nicolas DUVAL, en sa qualité de Président.
            </div>
            <div className="legal__text">
              1.4 - Hébergeur (ci-après « l'hébergeur ») :
            </div>
            <div className="legal__text">
              Microsoft France, Société par Actions Simplifiée (SAS), dont le siège social est situé{' '}
              <span className="number number--legal">37/45 Quai du Président Roosevelt 92130 Issy-les-Moulineaux</span>,
              immatriculée au RCS Nanterre B sous le numéro <span className="number number--legal">327 733 184</span>.
              Téléphone : <span className="number number--legal">+33 (0)9 70 01 90 90</span> (numéro non surtaxé depuis la France métropolitaine)
            </div>
            <div className="legal__text">1.5 - Autorisations</div>
            <div className="legal__text">
              N° Agrément : se référer à la page du centre correspondant
            </div>
            <div className="legal__text">
              Délivré pour l’accomplissement des transports sanitaires effectués
              dans le cadre des dispositions de l’article{' '}
              <span className="number number--legal">R6312.11</span> du code de
              la santé publique.
            </div>
          </div>
          <div className="legal__item">
            <div className="legal__title">Article 2 - Accès au site</div>
            <div className="legal__text">
              Toute personne peut accéder au site sous réserve de l’acceptation
              des conditions qui suivent.
            </div>
            <div className="legal__text">
              Vous vous engagez à ne pas utiliser ce site et les informations ou
              données qui y figurent à des fins commerciales, politiques,
              publicitaires et pour toute forme de sollicitation commerciale et
              notamment l'envoi de courriers électroniques non sollicités.
            </div>
          </div>
          <div className="legal__item">
            <div className="legal__title">Article 3 - Contenu du site</div>
            <div className="legal__text">
              Toutes les marques, photographies, textes, commentaires,
              illustrations, images animées ou non, séquences vidéo, sons, qui
              pourraient être utilisées pour faire fonctionner ce site et plus
              généralement tous les éléments reproduits ou utilisés sur le site
              sont protégés par les lois en vigueur au titre de la propriété
              intellectuelle.
            </div>
            <div className="legal__text">
              Ils sont la propriété pleine et entière de l'éditeur ou de ses
              partenaires. Toute reproduction, représentation, utilisation ou
              adaptation, sous quelque forme que ce soit, de tout ou partie de
              ces éléments, sans l'accord préalable et écrit de l'éditeur, sont
              strictement interdites. Le fait pour l'éditeur de ne pas engager
              de procédure dès la prise de connaissance de ces utilisations non
              autorisées ne vaut pas acceptation desdites utilisations et
              renonciation aux poursuites.
            </div>
          </div>
          <div className="legal__item">
            <div className="legal__title">Article 4 - Gestion du site</div>
            <div className="legal__text">
              Pour la bonne gestion du site, l'éditeur pourra à tout moment :
              <ul>
                <li>
                  suspendre, interrompre, limiter l'accès à tout ou partie du
                  site, réserver l'accès au site, ou à certaines parties du
                  site, et ce, sans préavis
                </li>
                <li>
                  supprimer toute information pouvant en perturber le
                  fonctionnement ou entrant en contravention avec les lois
                  nationales ou internationales, ou avec les règles de la
                  Nétiquette ;
                </li>
                <li>suspendre le site afin de procéder à des mises à jour.</li>
              </ul>
            </div>
          </div>
          <div className="legal__item">
            <div className="legal__title">Article 5 - Responsabilités</div>
            <div className="legal__text">
              La responsabilité de l'éditeur ne peut être engagée en cas de
              défaillance, panne, difficulté ou interruption de fonctionnement,
              empêchant l'accès au site ou à une de ses fonctionnalités.
            </div>
            <div className="legal__text">
              Le matériel de connexion au site que vous utilisez est sous votre
              entière responsabilité. Vous devez prendre toutes les mesures
              appropriées pour protéger votre matériel et vos propres données
              notamment en cas d'attaques virales par Internet. Vous êtes par
              ailleurs le seul responsable des sites et données que vous
              consultez.
            </div>
            <div className="legal__text">
              L'éditeur ne pourra être tenu responsable en cas de poursuites
              judiciaires à votre encontre :
              <ul>
                <li>
                  du fait de l'usage du site ou de tout service accessible via
                  Internet ;
                </li>
                <li>
                  du fait du non-respect par vous des présentes conditions
                  générales.
                </li>
              </ul>
            </div>
            <div className="legal__text">
              L'éditeur n'est pas responsable des dommages causés à vous-même, à
              des tiers et/ou à votre équipement du fait de votre connexion ou
              de votre utilisation du site et vous renoncez à toute action
              contre lui de ce fait.
            </div>
            <div className="legal__text">
              Si l'éditeur venait à faire l'objet d'une procédure amiable ou
              judiciaire à raison de votre utilisation du site, il pourra se
              retourner contre vous pour obtenir indemnisation de tous les
              préjudices, sommes, condamnations et frais qui pourraient découler
              de cette procédure.
            </div>
          </div>
          <div className="legal__item">
            <div className="legal__title">Article 6 - Liens hypertextes</div>
            <div className="legal__text">
              La mise en place par-vous de tous liens hypertextes vers tout ou
              partie du site est strictement interdite, sauf autorisation
              préalable et écrite de l'éditeur, sollicitée par courrier à
              l'adresse susmentionnée au 1.2 ou via le formulaire de contact
              www.jussieu-secours.fr/jussieu-secours-france/
            </div>
            <div className="legal__text">
              L'éditeur est libre de refuser cette autorisation sans avoir à
              justifier de quelque manière que ce soit sa décision. Dans le cas
              où l'éditeur accorderait son autorisation, celle-ci n'est dans
              tous les cas que temporaire et pourra être retirée à tout moment,
              sans obligation de justification à la charge de l'éditeur.
            </div>
            <div className="legal__text">
              Dans tous les cas, tout lien devra être retiré sur simple demande
              de l'éditeur.
            </div>
            <div className="legal__text">
              Toute information accessible via un lien vers d'autres sites n'est
              pas sous le contrôle de l'éditeur qui décline toute responsabilité
              quant à leur contenu.
            </div>
          </div>
          <div className="legal__item">
            <div className="legal__title">Article 7 - Cookies</div>
            <div className="legal__text">
              « JUSSIEU secours France » (ou « Nous ») met tout en œuvre pour
              protéger vos informations personnelles en conformité avec les
              réglementations européennes et françaises applicables.
            </div>
            <div className="legal__text">
              La présente politique de gestion des cookies (« Politique de
              Gestion des Cookies ») a vocation à vous informer des finalités et
              des conditions d’utilisation de fichiers cookies ou de données de
              navigation que nous sommes susceptibles de déployer sur nos sites
              Internet.
            </div>
            <div className="legal__text">
              Cette Politique complète la Politique de Confidentialité de
              JUSSIEU secours France et s’intègre dans les Conditions Générales
              d’Utilisation de nos sites Internet.
            </div>
            <div className="legal__text legal__text--bold">
              7.1 – Qu’est-ce qu’un cookie ?
            </div>
            <div className="legal__text">
              Les cookies sont des fichiers texte déposés sur votre terminal
              (ordinateur et/ou appareil mobile…) par le biais de votre
              navigateur Internet lorsque vous visitez un site internet.
            </div>
            <div className="legal__text legal__text--bold">
              7.2 – A quoi servent les cookies utilisés par JUSSIEU secours
              France ?
            </div>
            <div className="legal__text">
              JUSSIEU secours France en tant que responsable de traitement
              utilise des cookies afin de vous fournir une expérience
              utilisateur fluide et conviviale sur notre site internet. Ces
              cookies sont des cookies techniques nécessaires au bon
              fonctionnement du site, des cookies de mesure d'audience, et de
              marketing afin d’adapter nos contenus, de partager du contenu et
              de rendre notre site plus interactif. Certains cookies sont
              déposés directement par nos partenaires médias sociaux, de
              publicités ciblées et d’analyse, responsable de traitement
              distincts, et qu’ils pourront utiliser pour des finalités qu’ils
              leur sont propres, conformément à leur politique de
              confidentialité.
            </div>
            <div className="legal__text">
              Nous ne pouvons stocker des cookies sur votre appareil que s’ils
              sont strictement nécessaires au fonctionnement de ce site. Pour
              tous les autres types de cookies, nous avons besoin de votre
              consentement.
            </div>
            <div className="legal__text">
              La liste et la description de ces cookies figurent à l’article 7.
              Délais de conservation des données collectées de la page
              "Politique de confidentialité", les durées de conservation
              associées et gestion de votre consentement.
            </div>
            <div className="legal__text legal__text--bold">
              Les choix qui vous sont offerts via l'outil de mesure d'audience
              Google Analytics :
            </div>
            <div className="legal__text">
              Nous utilisons pour la gestion des cookies de mesures d’audience
              l’outil Google Analytics (
              <a
                className="legal__link link link--underline"
                rel="noreferrer"
                href={EXTERNAL_URLS.cookiesGoogleAnalytic}
              >
                {EXTERNAL_URLS.cookiesGoogleAnalytic}
              </a>
              ).
            </div>
            <div className="legal__text">
              L’utilisateur peut choisir de désactiver ces cookies directement
              en se rendant sur la page de l’outil, qui propose des informations
              spécifiques (
              <a
                className="legal__link link link--underline"
                rel="noreferrer"
                href={EXTERNAL_URLS.deactiveCookiesGoogleAnalytic}
              >
                {EXTERNAL_URLS.deactiveCookiesGoogleAnalytic}
              </a>
              ) et un module (
              <a
                className="legal__link link link--underline"
                rel="noreferrer"
                href={EXTERNAL_URLS.deactiveCookiesModuleGoogle}
              >
                {EXTERNAL_URLS.deactiveCookiesModuleGoogle}
              </a>
              ).
            </div>
            <div className="legal__text legal__text--bold">
              Les choix qui vous sont offerts via votre logiciel de navigation :
            </div>
            <div className="legal__text">
              Il est possible de configurer votre logiciel de navigation de
              manière à ce que des cookies soient enregistrés dans le terminal
              ou, au contraire, qu’ils soient rejetés, soit systématiquement,
              soit selon leur émetteur. Vous pouvez également configurer votre
              logiciel de navigation de manière à ce que l’acceptation ou le
              refus des cookies soient proposés ponctuellement, avant qu’un
              cookie soit susceptible d’être enregistré dans votre terminal.
            </div>
            <div className="legal__text">
              La configuration des cookies est différente en fonction de votre
              navigateur internet.
            </div>
            <div className="legal__text">
              Afin de savoir comment procéder pour paramétrer les cookies sur
              votre navigateur, vous devez vous rendre dans le menu « aide » ou
              « help » de votre navigateur qui lui permettra de savoir de quelle
              manière modifier vos souhaits en matière de cookies.
            </div>
            <div className="legal__text">
              Si vous avez accepté dans votre navigateur l’enregistrement de
              cookies sur votre terminal, ces derniers seront stockés
              temporairement dans un espace dédié de votre terminal.
            </div>
            <div className="legal__text">
              Pour Internet Explorer™ :&nbsp;
              <a
                className="legal__link link link--underline"
                rel="noreferrer"
                href={EXTERNAL_URLS.supportCookiesIE}
              >
                {EXTERNAL_URLS.supportCookiesIE}
              </a>
            </div>
            <div className="legal__text">
              Pour Safari™ :&nbsp;
              <a
                className="legal__link link link--underline"
                rel="noreferrer"
                href={EXTERNAL_URLS.supportCookiesSafari}
              >
                {EXTERNAL_URLS.supportCookiesSafari}
              </a>
            </div>
            <div className="legal__text">
              Pour Chrome™ :&nbsp;
              <a
                className="legal__link link link--underline"
                rel="noreferrer"
                href={EXTERNAL_URLS.supportCookiesChrome}
              >
                {EXTERNAL_URLS.supportCookiesChrome}
              </a>
            </div>
            <div className="legal__text">
              Pour Firefox™ :&nbsp;
              <a
                className="legal__link link link--underline"
                rel="noreferrer"
                href={EXTERNAL_URLS.supportCookiesFirefox}
              >
                {EXTERNAL_URLS.supportCookiesFirefox}
              </a>
            </div>
            <div className="legal__text">
              Pour plus d’informations, l’utilisateur peut également consulter
              le site de la Cnil :&nbsp;
              <a
                className="legal__link link link--underline"
                rel="noreferrer"
                href={EXTERNAL_URLS.cnil}
              >
                {EXTERNAL_URLS.cnil}
              </a>
              .
            </div>
            <div className="legal__text legal__text--bold">
              7.3 – Les cookies tiers déposés par nos partenaires
            </div>
            <div className="legal__text">
              Nos services vous permettent d’être redirigés vers des services de
              tiers via des boutons (twitter, YouTube…) et /ou de visualiser du
              contenu de tiers (vidéos, publicités etc.) sur notre site.
            </div>
            <div className="legal__text">
              Ces fonctionnalités utilisent des cookies tiers directement
              déposés par nos partenaires en tant que responsables de
              traitement.
            </div>
            <div className="legal__text">
              JUSSIEU secours France est indépendant de ces services fournis par
              ces tiers partenaires et n'a aucun contrôle ni responsabilité sur
              ces cookies et sur l’utilisation des données collectées par leur
              biais. A cet égard, nous vous conseillons de consulter les
              politiques de confidentialité de nos partenaires liée à ces
              cookies.
            </div>
            <div className="legal__text">
              Vous pouvez à tout moment consulter la liste de ses partenaires et
              paramétrer vos cookies pour les accepter ou les refuser en vous
              rendant sur le lien cookiebot à l’article 7. Délais de
              conservation des données collectées de la page "Politique de
              confidentialité".
            </div>
            <div className="legal__text">
              Vous pouvez aussi exprimer votre choix directement sur :
              <ul>
                <li>
                  Facebook -{' '}
                  <a
                    className="legal__link link"
                    rel="noreferrer"
                    target="_blank"
                    href={EXTERNAL_URLS.facebookPolicy}
                  >
                    Lien de désactivation
                  </a>
                </li>
                <li>
                  Twitter -{' '}
                  <a
                    className="legal__link link"
                    rel="noreferrer"
                    target="_blank"
                    href={EXTERNAL_URLS.twitterPolicy}
                  >
                    Lien de désactivation
                  </a>
                </li>
                <li>
                  Google -{' '}
                  <a
                    className="legal__link link"
                    rel="noreferrer"
                    target="_blank"
                    href={EXTERNAL_URLS.googlePolicy}
                  >
                    Lien de désactivation
                  </a>
                </li>
                <li>
                  Linkedin -{' '}
                  <a
                    className="legal__link link"
                    rel="noreferrer"
                    target="_blank"
                    href={EXTERNAL_URLS.linkedinPolicy}
                  >
                    Lien de désactivation
                  </a>
                </li>
                <li>
                  Yahoo -{' '}
                  <a
                    className="legal__link link"
                    rel="noreferrer"
                    target="_blank"
                    href={EXTERNAL_URLS.yahooPolicy}
                  >
                    Lien de désactivation
                  </a>
                </li>
                <li>
                  Youtube -{' '}
                  <a
                    className="legal__link link"
                    rel="noreferrer"
                    target="_blank"
                    href={EXTERNAL_URLS.youtubePolicy}
                  >
                    Lien de désactivation
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="legal__item">
            <div className="legal__title">Article 8 - Loi applicable</div>
            <div className="legal__text">
              Les présentes conditions d'utilisation du site sont régies par la
              loi française et soumises à la compétence des tribunaux de Tours (
              <span className="number number--legal">37000</span>) sous réserve
              d'une attribution de compétence spécifique découlant d'un texte de
              loi ou réglementaire particulier.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default observer(LegalNotice);
