import { action, makeObservable, observable } from 'mobx';
import RootStore from './RootStore';

export default class AppUiStore {
  rootStore: RootStore;
  // observables
  modal: AppModal | null = null;
  isHistoryBlocked: boolean = false;

  constructor(root: RootStore) {
    this.rootStore = root;
    makeObservable(this, {
      modal: observable,
      isHistoryBlocked: observable,
      openModal: action.bound,
      closeModal: action.bound,
      blockHistory: action.bound,
      unblockHistory: action.bound,
    });
  }

  openModal(modal: AppModal) {
    this.modal = modal;
  }

  closeModal() {
    this.modal = null;
  }

  blockHistory() {
    this.isHistoryBlocked = true;
  }

  unblockHistory() {
    this.isHistoryBlocked = false;
  }
}
