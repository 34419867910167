import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import ROUTES, { EXTERNAL_URLS, PRIVATE_ROUTES } from 'constants/routes';
import useRootStore from 'hooks/useRootStore';
import securityIconSrc from 'assets/images/icon-security.png';
import serviceIconSrc from 'assets/images/icon-service.png';
import certificationIconSrc from 'assets/images/icon-certification.jpeg';
import './Footer.scss';
import { useTranslation } from 'react-i18next';

interface Contact {
  email: string;
  phone?: string;
}

function Footer() {
  const location = useLocation();
  const { invoiceStore, globalStorage } = useRootStore();
  const { company } = globalStorage.getValues();

  const { t } = useTranslation();

  const isAuthenticatedPage = useMemo(() => {
    if (
      PRIVATE_ROUTES.includes(location.pathname) ||
      location.pathname === ROUTES.legalNotice
    ) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const contact = useMemo(() => {
    const companyInfo = invoiceStore.invoiceData?.company || company;

    if (isAuthenticatedPage && companyInfo) {
      const contactInfo: Contact = { email: companyInfo.email };

      if (companyInfo.phoneNumber) {
        contactInfo['phone'] = companyInfo.phoneNumber
          .replace('+33', '0')
          .match(/.{1,2}/g)
          ?.join(' ');
      }

      return contactInfo;
    }

    return null;
  }, [isAuthenticatedPage, invoiceStore.invoiceData?.company, company]);

  return (
    <footer className="footer">
      <section className="footer__card container">
        <div className="footer__card__item">
          <img
            className="footer__card__icon"
            src={securityIconSrc}
            alt="Security Icon"
          />
          <div className="footer__card__body">
            <p className="footer__card__title">
              {t('body.footer.footer__card__title1')}
            </p>
          </div>
        </div>
        <div className="footer__card__item">
          <img
            className="footer__card__icon"
            src={serviceIconSrc}
            alt="Service Icon"
          />
          <div className="footer__card__body">
            <p className="footer__card__title">
              {t('body.footer.footer__card__title2')}
            </p>
            {contact && (
              <>
                <p className="footer__card__info-title">
                  {t('body.footer.footer__card__info1')}
                </p>
                {contact.phone && (
                  <p className="footer__card__info">
                    {t('body.footer.footer__card__info2')}{' '}
                    <a
                      data-testid="footer-contact-phone"
                      href={`tel:${contact.phone}`}
                      className="footer__card__link number"
                    >
                      {contact.phone}
                    </a>
                  </p>
                )}
                {contact.email && (
                  <p className="footer__card__info">
                    {t('body.footer.footer__card__info3')}{' '}
                    <a
                      href={`mailto:${contact.email}`}
                      target="_blank"
                      rel="noreferrer"
                      className="footer__card__link"
                    >
                      {contact.email}
                    </a>
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="footer__card__item">
          <img
            className="footer__card__icon"
            src={certificationIconSrc}
            alt="Certification Icon"
          />
          <div className="footer__card__body">
            <p className="footer__card__title">
              {t('body.footer.footer__card__title3')}
            </p>
          </div>
        </div>
      </section>
      <section className="footer__nav">
        <p className="footer__nav__text">
          {t('body.footer__nav.footer__nav__text')}&nbsp;
          <a href={EXTERNAL_URLS.jussieuSecours} className="link">
            {t('body.footer__nav.footer__nav__url-text')}
          </a>
        </p>
        <Link
          className="footer__nav__link link"
          rel="noreferrer"
          to={ROUTES.legalNotice}
          target="_blank"
        >
          {t('body.footer__nav.footer__nav__link link1')}
        </Link>
        <a
          className="footer__nav__link link"
          target="_blank"
          rel="noreferrer"
          href={EXTERNAL_URLS.generalConditions}
        >
          {t('body.footer__nav.footer__nav__link link2')}
        </a>
      </section>
    </footer>
  );
}

export default observer(Footer);
