import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES, { PRIVATE_ROUTES } from 'constants/routes';
import useRootStore from 'hooks/useRootStore';

export default function useConfirmLeavePage() {
  const history = useHistory();
  const { appUiStore } = useRootStore();
  useEffect(() => {
    appUiStore.blockHistory();
  }, [appUiStore]);

  useEffect(() => {
    return history.block((e): any => {
      if (
        !appUiStore.isHistoryBlocked ||
        PRIVATE_ROUTES.includes(e.pathname) ||
        e.pathname === ROUTES.error
      ) {
        return true;
      }
      appUiStore.openModal({
        type: 'confirmLeavePage',
        params: { nextPage: e.pathname },
      });
      return false;
    });
  }, [history, appUiStore.isHistoryBlocked, appUiStore]);
}
