export default class StorageService<ValuesInterface extends Object> {
  private storage: Storage;
  private storageKey: string;
  private values: ValuesInterface;

  constructor(storage: Storage, storageKey: string) {
    this.storage = storage;
    this.storageKey = storageKey;
    this.values = this.loadValues();
  }

  protected loadValues() {
    const serializedValues = this.storage.getItem(this.storageKey);
    if (serializedValues) {
      try {
        return JSON.parse(serializedValues);
      } catch (err) {
        console.error('unable to load stored values', serializedValues);
      }
    }
    return {} as ValuesInterface;
  }

  protected saveValues(): void {
    try {
      this.storage.setItem(this.storageKey, JSON.stringify(this.values));
    } catch (err) {
      console.error('unable to save stored values', this.values);
    }
  }

  public getValues() {
    return this.values;
  }

  public setValue(key: keyof ValuesInterface, value: any) {
    this.values[key] = value;
    this.saveValues();
  }

  public clear() {
    if (Object.keys(this.values).length === 0) {
      return;
    }
    try {
      this.values = {} as ValuesInterface;
      this.saveValues();
    } catch (_) {}
  }
}
