import RootStore from './RootStore';
import { action, makeObservable, observable, runInAction } from 'mobx';
import CompaniesService from 'services/CompaniesService';

export default class CompaniesStore {
  rootStore: RootStore;
  companiesService: CompaniesService;
  // observables
  allCompanies: ApiCompanyItemWhenGetCompanies[] = [];
  error = false;
  isLoading = false;

  constructor(root: RootStore, companiesService: CompaniesService) {
    makeObservable(this, {
      allCompanies: observable,
      error: observable,
      isLoading: observable,
      setIsLoading: action.bound,
      fetchAllCompanies: action.bound,
    });
    this.rootStore = root;
    this.companiesService = companiesService;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  async fetchAllCompanies() {
    this.setIsLoading(true);
    try {
      const response = await this.companiesService.getAllCompanies();
      runInAction(() => {
        if (Array.isArray(response.data)) {
          this.allCompanies = response.data;
        }
      });
    } catch (err) {
      if (err !== 'session') {
        runInAction(() => {
          this.error = true;
        });
      }
    } finally {
      runInAction(() => {
        this.setIsLoading(false);
      });
    }
  }
}
