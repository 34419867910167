import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
// library
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import moment from 'moment';
// project import
import ROUTES from 'constants/routes';
import { APP_LANGUAGES } from 'constants/app';
import useRootStore from 'hooks/useRootStore';
import useConfirmLeavePage from 'hooks/useConfirmLeavePage';
import { getGender } from 'helpers/genders.helper';
import Button from 'components/ui/Button/Button';
import Card from 'components/ui/Card/Card';
import Collapse from 'components/ui/Collapse/Collapse';
import Loading from 'components/ui/Loading/Loading';
import iconTaxi from 'assets/images/icon-taxi.svg';
import iconAmbulance from 'assets/images/icon-ambulance.svg';
import iconVsl from 'assets/images/icon-vsl.svg';
import iconBanking from 'assets/images/icon-banking.svg';
import iconAddress from 'assets/images/icon-map.svg';
import iconWarning from 'assets/images/icon-warning.svg';
import './Invoice.scss';

const TRANSPORT_TYPES_MAPPING: any = {
  AMBULANCE: 'Ambulance',
  TAXI: 'Taxi',
  LIGHT_HEALTH_VEHICLE: 'VSL',
};

const TRANSPORT_ICONS_MAPPING: any = {
  AMBULANCE: iconAmbulance,
  TAXI: iconTaxi,
  LIGHT_HEALTH_VEHICLE: iconVsl,
};

function Invoice() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { appUiStore, invoiceStore } = useRootStore();
  const invoice = invoiceStore.invoiceData;
  const [isCollapseVisible, setCollapseVisible] = useState<boolean>(false);
  const isFromPOBApp = invoice?.origin === 'POB';

  const isLanguageFr = i18n.language === APP_LANGUAGES.FR;

  useConfirmLeavePage();

  const goToNextScreen = () => {
    history.push(ROUTES.payment);
  };

  useEffect(() => {
    invoiceStore.fetchInvoiceData();
  }, [invoiceStore]);

  useEffect(() => {
    if (invoiceStore.error) {
      history.push(ROUTES.error);
    }
  }, [invoiceStore.error, appUiStore, history]);

  const detectDisplayDate = useCallback((date: string) => {
    const comparedDate = moment('01-01-1970').format('DD/MM/YYYY');
    const currentDate = moment(date).format('DD/MM/YYYY');

    return !moment(currentDate).isSame(comparedDate);
  }, []);

  const startDateFormated = useMemo(
    () => moment(invoice?.transport.startDate).format('DD/MM/YYYY'),
    [invoice]
  );

  const endDateFormated = useMemo(
    () => moment(invoice?.transport.endDate).format('DD/MM/YYYY'),
    [invoice]
  );
  const billingDateFormated = useMemo(
    () => moment(invoice?.issueDate).format('DD MMM YYYY'),
    [invoice]
  );

  const pageSubTitle = (dunningCount: number) => {
    if (dunningCount === 2)
      return 'Première relance avant mise en recouvrement';
    if (dunningCount === 3)
      return 'Deuxième relance avant mise en recouvrement';

    return t('body.page-sub-title');
  };

  const toggleCollapseVisible = (): void => {
    setCollapseVisible((visible) => !visible);
  };

  return (
    <>
      {invoice && !invoiceStore.isLoading && (
        <section className="invoice container">
          <h1 data-testid="page-title-invoice" className="page-title">
            {invoice.status === 'SENT' &&
              !isFromPOBApp &&
              t('body.page-title-invoice')}
            {invoice.status === 'SENT' &&
              isFromPOBApp &&
              t('body.page-title-invoice-POB')}
            {invoice.status === 'PAID' &&
              !isFromPOBApp &&
              t('body.page-title-invoice-paid')}
            {invoice.status === 'PAID' &&
              isFromPOBApp &&
              t('body.page-title-invoice-paid-POB')}
            &nbsp;
            {isFromPOBApp
              ? ''
              : i18n.language === APP_LANGUAGES.EN
              ? 'no.'
              : 'n°'}
            <span className="number">{invoiceStore.invoiceNumber}</span>
          </h1>
          {invoice.status === 'SENT' && (
            <h3 className="page-sub-title">
              {(invoice.dunningCount === 2 || invoice.dunningCount === 3) && (
                <img
                  className="invoice__warning-icon"
                  src={iconWarning}
                  alt="Warning icon"
                />
              )}
              {pageSubTitle(invoice.dunningCount)}
            </h3>
          )}
          <div className="invoice__body">
            <div
              className={cn('induction', {
                'induction--paid': invoice.status === 'PAID',
              })}
            >
              <div className="induction__greet">
                {isFromPOBApp && t('body.induction__greet')}
                &nbsp;
                {isFromPOBApp
                  ? t('recap.greet')
                  : getGender(invoice.customer.title)}
                &nbsp;
                {isFromPOBApp
                  ? invoice.customer.lastName.toLocaleUpperCase()
                  : invoice.customer.lastName}
                ,
              </div>
              <div className="induction__content">
                {invoice.status === 'SENT' && (
                  <>
                    <p className="induction__content__item">
                      {isFromPOBApp
                        ? t('body.induction__content__item1__POB')
                        : t('body.induction__content__item1')}
                      &nbsp;
                      {!isFromPOBApp && (
                        <span className="number">
                          {invoice.amount}
                          {isLanguageFr && ' €.'}
                        </span>
                      )}
                    </p>
                    <p className="induction__content__item">
                      {isFromPOBApp
                        ? t('body.induction__content__item2__POB') +
                          ' ' +
                          invoice.amount +
                          ' ' +
                          t('body.induction__content__item2__POB__2')
                        : t('body.induction__content__item2')}
                    </p>
                    {!isFromPOBApp && (
                      <p className="induction__content__item">
                        {t('body.induction__content__item3')}
                      </p>
                    )}
                  </>
                )}
                {invoice.status === 'PAID' && (
                  <>
                    <p className="induction__content__item">
                      {t('already-paid.content-1')}
                    </p>
                    <p className="induction__content__item">
                      {t('already-paid.content-2')}
                    </p>
                    <p className="induction__content__item">
                      {t('already-paid.content-3')}&nbsp;{invoice.company.email}
                    </p>
                    <p className="induction__content__item induction__content__item--sign">
                      L'équipe Jussieu Secours
                    </p>
                  </>
                )}
              </div>
            </div>
            {invoice.status === 'SENT' && (
              <div className="payment">
                <Card>
                  <h3 className="payment__title">
                    {isFromPOBApp
                      ? t('body.card.payment__title__POB')
                      : t('body.card.payment__title')}
                  </h3>
                  <div className="payment__content">
                    <div className="transport">
                      <img
                        data-testid="invoice-transport-icon"
                        className="transport__icon"
                        src={
                          TRANSPORT_ICONS_MAPPING[
                            invoice.transport.transportType
                          ] || TRANSPORT_ICONS_MAPPING.AMBULANCE
                        }
                        alt="Transport type icon"
                      />

                      <div className="transport__content">
                        {isLanguageFr && (
                          <p className="transport__type">
                            Transport&nbsp;
                            <span
                              data-testid="invoice-transport-type-name"
                              className="transport__type__name"
                            >
                              {TRANSPORT_TYPES_MAPPING[
                                invoice.transport.transportType
                              ] || invoice.transport.transportType}
                            </span>
                            &nbsp;par&nbsp;
                            <span className="transport__type__name">
                              {invoice.company.name}
                            </span>
                          </p>
                        )}

                        {!isLanguageFr && (
                          <p className="transport__type">
                            <span
                              data-testid="invoice-transport-type-name"
                              className="transport__type__name"
                            >
                              {TRANSPORT_TYPES_MAPPING[
                                invoice.transport.transportType
                              ] || invoice.transport.transportType}
                            </span>{' '}
                            transport by &nbsp;
                            <span className="transport__type__name">
                              {invoice.company.name}
                            </span>
                          </p>
                        )}

                        {detectDisplayDate(startDateFormated) && (
                          <p
                            data-testid="invoice-transport-date"
                            className="transport__date"
                          >
                            {t('body.card.invoice-transport-date')}&nbsp;
                            {startDateFormated}
                            {startDateFormated !== endDateFormated ? (
                              <span>&nbsp;au {endDateFormated}</span>
                            ) : (
                              ''
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="action">
                      <div className="action__amount">
                        <span className="number">{invoice.amount}</span>€
                      </div>
                      <Button
                        className="action__button"
                        primary
                        onClick={goToNextScreen}
                      >
                        {t('body.card.action.action__button')}
                      </Button>
                      <div className="action__invoice">
                        {isFromPOBApp
                          ? t('body.card.action.action__transport')
                          : t('body.card.action.action__invoice')}
                        &nbsp;
                        {isFromPOBApp ? '' : isLanguageFr ? 'n°' : 'no.'}
                        <span className="number">{invoice.invoiceNumber}</span>
                      </div>
                      {invoice?.issueDate &&
                        detectDisplayDate(invoice?.issueDate) && (
                          <div className="action__time">
                            {t('body.card.action.action__time')}{' '}
                            {billingDateFormated}
                          </div>
                        )}
                    </div>
                  </div>
                </Card>
              </div>
            )}
          </div>
          {invoice.status === 'SENT' && !isFromPOBApp && (
            <div className="invoice__footer">
              <div className="panel">
                <div className="panel__item">
                  <div className="panel__title">
                    <div className="panel__icon-wrapper">
                      <FontAwesomeIcon
                        className="panel__icon"
                        icon={faQuestionCircle}
                      />
                    </div>
                    {t('body.panel__title1')}
                  </div>
                  <div className="panel__content">
                    {t('body.panel__content1')}
                  </div>
                </div>
                <div className="panel__item">
                  <div className="panel__title">
                    <div className="panel__icon-wrapper">
                      <FontAwesomeIcon
                        className="panel__icon"
                        icon={faSyncAlt}
                      />
                    </div>
                    {t('body.panel__title2')}
                  </div>
                  <div className="panel__content">
                    {t('body.panel__content2')}
                  </div>
                </div>
              </div>
              <div className="card card--dark">
                <Collapse
                  toggleBtnLabel={t('body.collapse.collapse__toggle-btn')}
                  isVisible={isCollapseVisible}
                  onToggleVisible={toggleCollapseVisible}
                >
                  <div className="info">
                    <div className="info__item">
                      {t('body.collapse.content1')}&nbsp;
                      <div className="info__main info__main--address">
                        <img
                          className="info__icon info__icon--address"
                          src={iconAddress}
                          alt="Address icon"
                        />
                        <span className="number">
                          {invoice.company.address}
                        </span>
                      </div>
                      {t('body.collapse.content2')} (
                      <span className="info__main info__main--company-name">
                        {invoice.company.name}
                      </span>
                      ) {t('body.collapse.content3')}
                    </div>
                    <div className="info__item">
                      {t('body.collapse.content4')}&nbsp;
                      <div className="info__main info__main--bank">
                        <img
                          className="info__icon"
                          src={iconBanking}
                          alt="Banking icon"
                        />
                        <span className="number">
                          <span>IBAN: {invoice.company.iban}</span>
                          &nbsp;&nbsp;-&nbsp;&nbsp;
                          <span>BIC: {invoice.company.bic}</span>
                        </span>
                      </div>
                      {t('body.collapse.content5')} (
                      <span className="info__main info__main--company-name">
                        {invoice.company.name}
                      </span>
                      ) {t('body.collapse.content6')}
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          )}
        </section>
      )}
      <Loading isLoading={invoiceStore.isLoading} />
    </>
  );
}

export default observer(Invoice);
