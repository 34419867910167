export const STORAGE_KEY_APP = '__ksapp__';
export const STORAGE_KEY_GLOBAL = '__ksglobal__';

export const IS_DEV = (process as any).env.NODE_ENV === 'development';
export const IS_PROD = !IS_DEV;

export const WORDINGS = {
  ERROR: {
    AUTH_FAILED:
      "Oups, aucune facture n'a été trouvée. Nous vous invitons à vérifier l'exactitude des informations saisies (informations disponibles sur votre facture papier). Si le problème persiste, n'hésitez pas à nous contacter.",
    SESSION_EXPIRED:
      'Oups, votre session a expiré. Merci de recommencer pour finaliser votre opération de paiement en ligne. Merci 😀!',
  },
  MESSAGES: {
    NO_OPTION: 'Pas de résultats',
    LOADING: 'Chargement...',
  },
  ERROR_MODAL: {
    TITLE: 'Une erreur inattendue est survenue.',
    BODY: 'Merci de recommencer votre opération ultérieurement.',
    FOOTER: null,
    CTA: "Retour à l'accueil",
  },
  PAYMENT_ERROR_MODAL: {
    TITLE: 'Tentative de paiement échouée',
    BODY: 'Merci de réessayer plus tard',
    CTA: 'Retour à ma facture',
  },
  GENDERS: {
    MR: 'Monsieur',
    MRS: 'Madame',
    DEFAULT: 'Madame/Monsieur',
  },
};

export const DEAULT_INVOICE_SUPPORT_EMAIL = 'facturation441@jussieu-secours.fr';

export const APP_LANGUAGES: Record<Uppercase<Language>, Language> = {
  FR: 'fr',
  EN: 'en',
};
