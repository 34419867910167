import axios, { AxiosResponse } from 'axios';

export default class InvoicesService {
  getInvoiceById(id: string): Promise<AxiosResponse<ApiInvoiceItem>> {
    return axios(`/v1/invoices/${id}`);
  }

  getInvoiceDocumentById(
    id: string
  ): Promise<AxiosResponse<BlobPart>> {
    return axios(`/v1/invoices/${id}/document`, { responseType: 'blob' });
  }
}
