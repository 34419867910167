import {
  ReactElement,
  HTMLAttributes,
  useRef,
  useEffect,
  useState,
  MouseEventHandler,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import './Collapse.scss';

interface Props extends HTMLAttributes<HTMLElement> {
  isVisible: boolean;
  children: string | ReactElement;
  toggleBtnLabel: string;
  onToggleVisible: MouseEventHandler<HTMLButtonElement>;
}

export default function Collapse({
  isVisible,
  children,
  toggleBtnLabel,
  onToggleVisible,
}: Props) {
  const [collapseRect, setCollapseRect] = useState<DOMRect | undefined>(
    undefined
  );
  const collapseRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeHandler = () => {
      // Unset collapse's height to get the real height
      setCollapseRect(undefined);
      setCollapseRect(collapseRef.current?.getBoundingClientRect());
    };

    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const collapseStyle = {
    height: isVisible && collapseRect ? `${collapseRect.height}px` : '0',
    opacity: 1,
  };

  return (
    <div className="collapse">
      <button
        onClick={onToggleVisible}
        type="button"
        className="collapse__toggle-btn"
      >
        <FontAwesomeIcon
          key="collapse-icon"
          className="collapse__icon"
          icon={isVisible ? faChevronDown : faChevronRight}
          style={{width: '15px'}}
        />
        {toggleBtnLabel}
      </button>
      <div
        data-testid="collapse-body"
        ref={collapseRef}
        style={collapseRect ? collapseStyle : undefined}
        className="collapse__body"
      >
        {children}
      </div>
    </div>
  );
}
