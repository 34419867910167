import { useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import cn from 'classnames';
import Select, { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';
import useRootStore from 'hooks/useRootStore';
import './Landing.scss';
import { WORDINGS } from 'constants/app';
import { EXTERNAL_URLS } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import Card from 'components/ui/Card/Card';
import Form from 'components/ui/Form/Form';
import Loading from 'components/ui/Loading/Loading';
import Tooltip from 'components/ui/Tooltip/Tooltip';
import advItems from 'assets/images/adv-items.svg';
import logoBlue from 'assets/images/logo-blue.svg';
import advBackground from 'assets/images/adv-bg-mobile.svg';

interface FormValues {
  customerLastName: string;
  invoiceNumber: string;
  companyId: string;
}

const requiredErrorMess = 'Champ obligatoire';

function Landing() {
  const { companiesStore, authStore, globalStorage } = useRootStore();
  const { isLoading } = companiesStore;
  const companiesOptions = companiesStore.allCompanies;

  useEffect(() => {
    authStore.clear();
    globalStorage.clear();
    companiesStore.fetchAllCompanies();
  }, [companiesStore, authStore, globalStorage]);

  const formik = useFormik<FormValues>({
    initialValues: {
      customerLastName: '',
      invoiceNumber: '',
      companyId: '',
    },
    onSubmit: (values: FormValues) => {
      authStore.authenticateFromInvoiceForm(
        values.customerLastName,
        values.companyId,
        values.invoiceNumber
      );
    },
    validationSchema: Yup.object().shape({
      customerLastName: Yup.string().required(requiredErrorMess),
      invoiceNumber: Yup.string()
        .required(requiredErrorMess)
        .length(
          10,
          'Le numéro de facture renseigné est incorrect, il devrait contenir 10 chiffres.'
        ),
      companyId: Yup.string().required(requiredErrorMess),
    }),
  });

  const formGroupError = (groupName: string) => {
    const touched: { [key: string]: boolean } = formik.touched;
    const errors: { [key: string]: string } = formik.errors;

    return (
      errors[groupName]?.length &&
      touched[groupName] && (
        <div className="form__error">{errors[groupName]}</div>
      )
    );
  };

  const handleCompanyChange = (selectedOption: OptionTypeBase | null) =>
    formik.setFieldValue('companyId', selectedOption?.id);

  const handleCompanyBlur = () => formik.setFieldTouched('companyId');

  const handleInvoiceNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    const numberRegex = /^[0-9\b]+$/;

    if (!value.length || numberRegex.test(value)) {
      formik.setFieldValue('invoiceNumber', value);
    }
  };

  const companyValue = companiesOptions
    ? companiesOptions.find(
        (item: ApiCompanyItemWhenGetCompanies) =>
          item?.id === formik.values.companyId
      )
    : null;

  const getNoOptionMessage = () => {
    if (isLoading) return WORDINGS.MESSAGES.LOADING;
    return WORDINGS.MESSAGES.NO_OPTION;
  };

  return (
    <section className="landing container">
      <div className="landing__intro">
        <div className="landing__intro__title">
          Bienvenue sur notre espace de paiement en ligne.
        </div>
        <div className="landing__intro__sub-title">
          Pour régler votre facture, merci de renseigner les éléments suivants :
        </div>
      </div>
      <div className="landing__main">
        <div className="landing__adv-wrap">
          <div className="landing__adv">
            <div className="landing__adv-left">
              <div className="landing__adv-tag">
                <img src={logoBlue} alt="logo" className="icon" />
                <span>Nouveau !</span>
              </div>
              <h3 className="title">Votre espace personnalisé</h3>
              <p className="desc">
                Suivez vos factures, faites vos réservations et téléchargez vos
                pièces administratives directement sur votre compte patient.
              </p>
              <a
                href={EXTERNAL_URLS.webPatient}
                target="_blank"
                rel="noreferrer"
                className="button button--primary-blue"
              >
                Découvrir l’espace patient
              </a>
            </div>
            <div className="landing__adv-right">
              <img
                src={advBackground}
                alt="adv-background"
                className="img-background"
              />
              <img src={advItems} alt="adv-items" className="img-items" />
            </div>
          </div>
        </div>
        <Card className="card--landing">
          <Form className="form--landing" onSubmit={formik.handleSubmit}>
            {authStore.errorMessage && (
              <div className="form__error form__error--global">
                {authStore.errorMessage}
              </div>
            )}

            <div className="form__group">
              <div className="form__label-container form__label-container--landing">
                <label className="form__label" htmlFor="customerLastName">
                  Nom
                </label>
                <Tooltip
                  className="tooltip--landing"
                  label="i"
                  direction="right"
                >
                  Votre nom de famille tel qu’orthographié sur nos factures.
                </Tooltip>
              </div>
              <input
                id="customerLastName"
                name="customerLastName"
                data-testid="login-form-name"
                type="text"
                placeholder="Saisissez votre nom"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.customerLastName}
                className={cn('form__field form__field--text', {
                  'form__field--error': !!formGroupError('customerLastName'),
                })}
              />
              {formGroupError('customerLastName')}
            </div>

            <div className="form__group">
              <div className="form__label-container form__label-container--landing">
                <label className="form__label" htmlFor="invoiceNumber">
                  Numéro de facture
                </label>
                <Tooltip
                  className="tooltip--landing"
                  label="i"
                  direction="right"
                >
                  Retrouvez le numéro à 10 chiffres mentionnés sur nos
                  correspondances.
                </Tooltip>
              </div>
              <input
                id="invoiceNumber"
                name="invoiceNumber"
                data-testid="login-form-invoice"
                type="text"
                placeholder="Saisissez un numéro de facture"
                maxLength={10}
                onChange={handleInvoiceNumberChange}
                onBlur={formik.handleBlur}
                value={formik.values.invoiceNumber}
                className={cn('form__field form__field--number', {
                  'form__field--error': !!formGroupError('invoiceNumber'),
                })}
              />
              {formGroupError('invoiceNumber')}
            </div>

            <div className="form__group">
              <div className="form__label-container form__label-container--landing">
                <label className="form__label" htmlFor="companyId">
                  Société
                </label>
                <Tooltip
                  className="tooltip--landing"
                  label="i"
                  direction="right"
                >
                  La société est mentionnée sur nos correspondances.
                  Choisissez-la dans la liste déroulante.
                </Tooltip>
              </div>
              <Select
                inputId="companyId"
                onChange={(selectedOption: OptionTypeBase | null) =>
                  handleCompanyChange(selectedOption)
                }
                onBlur={handleCompanyBlur}
                value={companyValue}
                options={companiesOptions}
                placeholder="Veuillez selectionner la société associée à votre facture"
                className={cn({
                  'form__field--error': !!formGroupError('companyId'),
                })}
                classNamePrefix="react-select"
                blurInputOnSelect={false}
                getOptionValue={(company) => company.id}
                getOptionLabel={(company) => company.name}
                noOptionsMessage={getNoOptionMessage}
              />
              {formGroupError('companyId')}
            </div>
            <div className="btn-submit-wrapper">
              <Button
                type="submit"
                data-testid="login-form-submit"
                className="btn-submit"
                primary
              >
                Valider
              </Button>
            </div>
          </Form>
        </Card>
        <div className="landing__info">
          <div className="landing__info__item">
            <div className="landing__info__title">
              Pourquoi dois-je payer cette facture ?
            </div>
            <div className="landing__info__content">
              Malheureusement, nous n’avons pas pu facturer directement les
              frais à votre organisme de sécurité sociale ou votre mutuelle.
              C’est pourquoi le montant de votre transport sanitaire est à votre
              charge.
            </div>
          </div>
          <div className="landing__info__item">
            <div className="landing__info__title">
              Pourrais-je me faire rembourser ?
            </div>
            <div className="landing__info__content">
              Si votre transport est éligible au remboursement, le justificatif
              de paiement qui vous sera adressé à réception de votre règlement
              pourra être envoyé à votre organisme de sécurité sociale ou votre
              mutuelle, vous permettant ainsi de bénéficier du remboursement.
            </div>
          </div>
          <div className="landing__info__item">
            <div className="landing__info__title">
              J’ai déjà payé, pourquoi ai-je reçu cette relance ?
            </div>
            <div className="landing__info__content">
              Nous n’avons peut-être pas reçu votre règlement. Pour s’en
              assurer, merci de nous contacter via nos coordonnées présentes
              dans le courrier.
            </div>
          </div>
        </div>
      </div>
      <Loading isLoading={authStore.isLoading} />
    </section>
  );
}

export default observer(Landing);
