import { Link } from 'react-router-dom';
// library
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
// project import
import switchLanguageIcon from 'assets/images/switch-language-icon.png';
import logoSrc from 'assets/images/logo.png';
import { useAppTranslate } from 'hooks/useAppTranstate';
import { APP_LANGUAGES } from 'constants/app';

import './Header.scss';

interface HeaderProps {
  enableTranslate: boolean;
}

export default function Header({ enableTranslate }: HeaderProps) {
  const { i18n, t } = useTranslation();

  const changeLanguage = () => {
    if (i18n.language === APP_LANGUAGES.EN) {
      i18n.changeLanguage(APP_LANGUAGES.FR);
    } else {
      i18n.changeLanguage(APP_LANGUAGES.EN);
    }
  };

  return (
    <header className="header">
      <section className="header__content container">
        <Link to="/">
          <img
            className="header__logo"
            src={logoSrc}
            alt="JUSSIEU Secours Logo"
          />
        </Link>
        <div className="header__content__right">
          <div className="header__info">
            <FontAwesomeIcon icon={faLock} className="header__info__icon" />
            <p
              className="header__info__text"
              dangerouslySetInnerHTML={{
                __html: t('header.header__info__text'),
              }}
            ></p>
          </div>
          {enableTranslate && (
            <div className="header__translate">
              <img onClick={changeLanguage} src={switchLanguageIcon} alt="" />
            </div>
          )}
        </div>
      </section>
    </header>
  );
}
