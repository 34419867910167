import Loading from 'components/ui/Loading/Loading';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

export default function PaymentResult() {
  const { search } = useLocation();

  useEffect(() => {
      const queryParams = new URLSearchParams(search);
      const state = queryParams.get('state') || 'unknown';
      const transactionNumber = queryParams.get('transaction-number') || 'inconnu';
      window.parent.postMessage({ 
        messageType: 'payment-result',
        state,
        transactionNumber
      }, '*');
  }, [search]);

  return (
    <>
      <Loading />      
    </>
  );
}
