const ROUTES = {
  home: '/',
  invoice: '/invoice',
  payment: '/payment',
  legalNotice: '/legal-notice',
  redirectToInvoice: '/:redirect_token',
  recap: '/recap',
  error: '/error',
  initPayment: '/init-payment',
  paymentResult: '/payment-result',
};

export default ROUTES;

export const PRIVATE_ROUTES = [ROUTES.invoice, ROUTES.payment, ROUTES.recap];

export const NO_LAYOUT_ROUTES = [ROUTES.initPayment, ROUTES.paymentResult];

export const EXTERNAL_URLS = {
  webPatient:
    process.env.REACT_APP_WEB_PATIENT_URL ||
    'https://dev.patient.jussieu-secours.fr/',
  legalNotices: 'https://www.jussieu-secours.fr/mentions-legales.html',
  generalConditions:
    'https://www.jussieu-secours.fr/politique-de-confidentialite.html',
  jussieuSecours: 'https://www.jussieu-secours.fr',
  cookiesGoogleAnalytic: 'https://support.google.com/analytics/answer/6004245',
  deactiveCookiesGoogleAnalytic:
    'https://support.google.com/analytics/answer/181881?hl=fr&ref_topic=2919631',
  deactiveCookiesModuleGoogle: 'https://tools.google.com/dlpage/gaoptout',
  supportCookiesIE:
    'http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies',
  supportCookiesSafari:
    'https://support.apple.com/fr-fr/guide/safari/sfri11471/mac',
  supportCookiesChrome:
    'http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647',
  supportCookiesFirefox:
    'https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences',
  cnil: 'https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser',
  facebookPolicy: 'https://www.facebook.com/about/privacy/',
  twitterPolicy: 'https://twitter.com/privacy?lang=fr',
  googlePolicy: 'https://support.google.com/accounts/answer/61416?hl=fr',
  linkedinPolicy: 'https://www.linkedin.com/legal/cookie-policy',
  yahooPolicy: 'https://info.yahoo.com/privacy/us/yahoo/cookies/',
  youtubePolicy: 'https://support.google.com/accounts/answer/61416?hl=fr',
};
