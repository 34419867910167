import RootStore from './RootStore';
import { action, makeObservable, observable, runInAction } from 'mobx';
import AuthService from 'services/AuthService';
import { WORDINGS } from 'constants/app';
import ROUTES from 'constants/routes';
import { AxiosResponse } from 'axios';

export default class AuthStore {
  rootStore: RootStore;
  authService: AuthService;
  // observable values
  accessToken: null | string = null;
  errorMessage = '';
  isLoading = false;

  constructor(root: RootStore, authService: AuthService) {
    this.rootStore = root;
    this.authService = authService;
    makeObservable(this, {
      accessToken: observable,
      errorMessage: observable,
      isLoading: observable,
      authenticateFromInvoiceForm: action.bound,
      clear: action.bound,
      setAccessToken: action.bound,
      setErrorMessage: action.bound,
      setIsLoading: action.bound,
    });
  }

  setAccessToken(token: string | null) {
    this.accessToken = token;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  clearErrorMessage() {
    if (this.errorMessage !== '') {
      this.setErrorMessage('');
    }
  }

  async authenticateFromInvoiceForm(
    userName: string,
    companyId: string,
    invoiceNumber: string
  ) {
    this.clearErrorMessage();
    this.setIsLoading(true);
    try {
      const response = await this.authService.getAuthToken({
        invoice_number: invoiceNumber,
        customer_last_name: userName,
        company_id: companyId,
      });
      runInAction(() => {
        this.authenticate(response);
      });
    } catch (error) {
      runInAction(() => {
        this.setErrorMessage(WORDINGS.ERROR.AUTH_FAILED);
      });
    } finally {
      runInAction(() => {
        this.setIsLoading(false);
      });
    }
  }

  async authenticateWithRedirectToken(accessToken: string) {
    this.setIsLoading(true);
    try {
      const response = await this.authService.getRedirectToken({
        access_token: accessToken,
      });
      runInAction(() => {
        this.authenticate(response);
      });
    } catch (error) {
      this.rootStore.navigate(ROUTES.error);
    } finally {
      runInAction(() => {
        this.setIsLoading(false);
      });
    }
  }

  clear() {
    if (this.accessToken !== null) {
      this.setAccessToken(null);
    }
    if (this.errorMessage !== WORDINGS.ERROR.SESSION_EXPIRED) {
      this.clearErrorMessage();
    }
    this.rootStore.storageService.clear();
  }

  private authenticate(response: AxiosResponse<ApiAuthToken>): void {
    const { access_token, invoice_id } = response.data;
    const accessToken = access_token.access_token;
    this.setAccessToken(accessToken);
    this.rootStore.invoiceStore.setInvoiceId(invoice_id);
    this.rootStore.storageService.setValue('accessToken', accessToken);
    this.rootStore.storageService.setValue('invoiceId', invoice_id);
    this.setErrorMessage('');
    this.rootStore.navigate(ROUTES.invoice);
  }
}
