import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Loading.scss';

export interface LoadingProps {
  isLoading?: boolean;
  delayDuration?: number;
}

const DELAY_DURATION = 0.2;

export default function Loading({
  isLoading = true,
  delayDuration = DELAY_DURATION,
}: LoadingProps) {
  const [visible, setVisible] = useState(delayDuration === 0);

  useEffect(() => {
    if (!delayDuration) {
      return;
    }

    const timeout = setTimeout(() => {
      setVisible(isLoading);
    }, delayDuration * 1000);

    return () => clearTimeout(timeout);
  }, [isLoading, delayDuration]);

  if (!visible) {
    return null;
  }

  return (
    <div data-testid="loading" className="loading">
      <FontAwesomeIcon className="loading__icon" icon={faSpinner} spin />
    </div>
  );
}
