import { ButtonHTMLAttributes, ReactElement } from 'react';
import cn from 'classnames';
import './Button.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string | ReactElement;
  primary?: boolean;
  secondary?: boolean;
  primaryBlue?: boolean;
}

export default function Button({
  children,
  primary,
  secondary,
  primaryBlue,
  className,
  ...props
}: Props) {
  return (
    <button
      className={cn('button', className, {
        'button--primary': primary,
        'button--secondary': secondary,
        'button--primary-blue': primaryBlue,
      })}
      {...props}
    >
      {children}
    </button>
  );
}
