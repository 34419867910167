import RootStore from './RootStore';
import { action, makeObservable, observable, runInAction } from 'mobx';
import CustomerContactService from 'services/CustomerContactService';
import { mapCustomerContactData } from 'utils/data-mappers';
import PaymentService from 'services/PaymentService';

export default class CustomerContactStore {
  rootStore: RootStore;
  customerContactService: CustomerContactService;
  paymentService: PaymentService;
  // observables
  customerContact: CustomerContactEntity | null = null;
  error = false;
  isContactUpdated = false;
  isLoading = false;

  constructor(
    root: RootStore,
    customerContactService: CustomerContactService,
    paymentService: PaymentService
  ) {
    makeObservable(this, {
      customerContact: observable,
      error: observable,
      isContactUpdated: observable,
      isLoading: observable,
      setIsLoading: action.bound,
      fetchCustomerContact: action.bound,
    });
    this.rootStore = root;
    this.customerContactService = customerContactService;
    this.paymentService = paymentService;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  async fetchCustomerContact(customerId: string) {
    this.setIsLoading(true);
    try {
      const response = await this.customerContactService.getCustomerContact(
        customerId
      );
      runInAction(() => {
        if (response.data) {
          this.customerContact = mapCustomerContactData(response.data);
        }
      });
    } catch (err) {
      if (err !== 'session') {
        runInAction(() => {
          this.error = true;
        });
      }
    } finally {
      runInAction(() => {
        this.setIsLoading(false);
      });
    }
  }

  async handleCustomerContactUpdated(
    customerId: string,
    data: ApiCustomerContactUpdated
  ) {
    this.setIsLoading(true);
    try {
      this.isContactUpdated = true;
      await this.customerContactService.updateCustomerContact(customerId, data);

      const invoiceId = this.rootStore.invoiceStore.invoiceId;
      await this.paymentService.sendEmailConfirmation(invoiceId);
    } catch (err) {
      if (err !== 'session') {
        runInAction(() => {
          this.error = true;
        });
      }
    } finally {
      runInAction(() => {
        this.setIsLoading(false);
      });
    }
  }
}
