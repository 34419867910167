import { Redirect } from 'react-router';
import { useMemo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getGender } from 'helpers/genders.helper';
import { DEAULT_INVOICE_SUPPORT_EMAIL } from 'constants/app';
import ROUTES, { EXTERNAL_URLS } from 'constants/routes';
import useConfirmLeavePage from 'hooks/useConfirmLeavePage';
import useRootStore from 'hooks/useRootStore';
import Button from 'components/ui/Button/Button';
import Card from 'components/ui/Card/Card';
import Form from 'components/ui/Form/Form';
import './Recap.scss';
import { useTranslation } from 'react-i18next';

interface FormValues {
  customerEmail: string;
  customerEmailConfirmation: boolean;
}

const ERROR_MESSAGES = {
  emailFormat: 'Format de l`adresse email invalide',
  required: 'Champ obligatoire',
  confimationRequired: 'Vous devez cocher la case',
};

function Recap() {
  const { t } = useTranslation();
  const {
    invoiceStore,
    paymentStore,
    customerContactStore,
    customerConsentStore,
  } = useRootStore();
  const {
    customerContact,
    isContactUpdated,
    error: customerContactError,
    isLoading: isCustomerContactLoading,
  } = customerContactStore;
  const invoice = invoiceStore.invoiceData;
  const currentConsent = customerConsentStore.currentConsent;
  const customerId = invoice?.customer?.id;
  const isFromPOB = invoice?.origin === 'POB';

  useConfirmLeavePage();

  useEffect(() => {
    if (customerId) {
      customerContactStore.fetchCustomerContact(customerId);
      customerConsentStore.fetchCurrentConsent();
    }
  }, [customerContactStore, customerConsentStore, customerId]);

  const downloadErrorMessage = useMemo(() => {
    const email = invoice?.company?.email ?? DEAULT_INVOICE_SUPPORT_EMAIL;
    return (
      <div className="form__error form__error--global">
        Une erreur est survenue lors du téléchargement de votre facture.
        <br />
        Pour recevoir votre document merci de contacter le service facturation à
        l'adresse email suivante
        {'\u00A0:\u00A0'}
        <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
          {email}
        </a>{' '}
        pour recevoir votre document.
      </div>
    );
  }, [invoice]);

  const emailSuccessMessage = (
    <div className="recap__email__success">{t('recap.email__success')}</div>
  );

  const ondownloadInvoiceDocument = () => {
    invoiceStore.downloadInvoiceDocument();
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      customerEmail: '',
      customerEmailConfirmation: false,
    },
    onSubmit: (values: FormValues) => {
      if (customerId && currentConsent) {
        customerContactStore.handleCustomerContactUpdated(customerId, {
          email: values.customerEmail,
          customer_consent_version: currentConsent.currentVersion,
        });
      }
    },
    validationSchema: Yup.object().shape({
      customerEmail: Yup.string()
        .required(ERROR_MESSAGES.required)
        .email(ERROR_MESSAGES.emailFormat),
      customerEmailConfirmation: Yup.boolean().isTrue(
        ERROR_MESSAGES.confimationRequired
      ),
    }),
  });

  if (!invoice) {
    return <Redirect to={ROUTES.error} />;
  }

  const formGroupError = (groupName: string) => {
    const touched: { [key: string]: boolean } = formik.touched;
    const errors: { [key: string]: string } = formik.errors;
    return (
      errors[groupName]?.length &&
      touched[groupName] && (
        <div className="form__error">{errors[groupName]}</div>
      )
    );
  };
  return (
    <div className="recap container">
      <div className="recap__header">
        <FontAwesomeIcon className="recap__icon" icon={faCheckCircle} />
        <div className="page-title">{t('recap.page-title')}</div>
        <div className="recap__sub-title">
          Transaction n°{' '}
          <span className="number">{paymentStore.transactionNumber}</span>
        </div>
      </div>
      <div className="recap__body">
        <div className="recap__greet">
          {isFromPOB ? t('recap.greet') : getGender(invoice.customer.title)}{' '}
          {invoice.customer.lastName}
        </div>
        <div className="recap__content">
          {t('recap.content__1')}&nbsp;
          <span className="number">{invoice.amount}</span>
          {t('recap.content__2')}&nbsp;
          <span className="number">{invoice.invoiceNumber}</span>.
        </div>
        <div className="recap__btn-wrapper">
          {invoiceStore.hasDocumentDownloadError
            ? downloadErrorMessage
            : !isFromPOB && (
                <Button
                  onClick={ondownloadInvoiceDocument}
                  className="recap__btn"
                  primary
                  disabled={invoiceStore.isLoading}
                >
                  Télécharger votre justificatif
                </Button>
              )}
        </div>

        {isCustomerContactLoading && (
          <div className="recap__email-loader">
            <FontAwesomeIcon className="loading__icon" icon={faSpinner} spin />
          </div>
        )}
        {customerContact && customerContact.hasAnEmail && emailSuccessMessage}
        {customerContact && !customerContact.hasAnEmail && (
          <div className="recap__email">
            {!isContactUpdated ? (
              <Card>
                <Form
                  className="recap__email__form"
                  onSubmit={formik.handleSubmit}
                >
                  <h2 className="recap__email__title">
                    Veuillez renseigner votre adresse email pour recevoir votre
                    justificatif de paiement{'\u00A0'}:
                  </h2>
                  <div className="recap__email__input">
                    <div className="form__group form__group--email">
                      <input
                        id="customerEmail"
                        name="customerEmail"
                        data-testid="customer-email"
                        type="text"
                        placeholder="Saisissez votre email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.customerEmail}
                        className={cn('form__field form__field--text', {
                          'form__field--error':
                            !!formGroupError('customerEmail'),
                        })}
                      />
                      {formGroupError('customerEmail')}
                    </div>
                  </div>
                  <div className="recap__email__confirmation">
                    <div className="recap__email__input">
                      <div className="form__group">
                        <label
                          className="form__checkbox-container"
                          htmlFor="customerEmailConfirmation"
                        >
                          <input
                            id="customerEmailConfirmation"
                            name="customerEmailConfirmation"
                            data-testid="customer-email-confirm"
                            type="checkbox"
                            placeholder="Saisissez votre nom"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.customerEmailConfirmation}
                            className={cn(
                              'form__field form__field--confirmation',
                              {
                                'form__field--error': !!formGroupError(
                                  'customerEmailConfirmation'
                                ),
                              }
                            )}
                          />
                          <div className="form__checkmark"></div>
                          <div className="form__label">
                            J'accepte que mon email soit utilisé pour l'échange
                            d'informations et de documents liés à mes
                            prestations de transport conformément aux{' '}
                            <a
                              className="link form__link"
                              target="_blank"
                              rel="noreferrer"
                              href={EXTERNAL_URLS.legalNotices}
                            >
                              mentions légales de Jussieu Secours France.
                            </a>
                          </div>
                        </label>
                        {formGroupError('customerEmailConfirmation')}
                      </div>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    data-testid="customer-email-form-submit"
                    className="btn-submit"
                    primary
                    disabled={customerContactStore.isLoading}
                  >
                    Valider
                  </Button>
                </Form>
              </Card>
            ) : (
              <>
                {customerContactError ? (
                  <div className="recap__email__error">
                    Une erreur est survenue lors de la validation du formulaire.
                  </div>
                ) : (
                  emailSuccessMessage
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(Recap);
