import { observer } from 'mobx-react-lite';
import Modal from 'components/ui/Modal/Modal';
import useRootStore from 'hooks/useRootStore';
import { useHistory } from 'react-router';

function LeavePageModal() {
  const { appUiStore } = useRootStore();
  const history = useHistory();
  if (appUiStore.modal?.type !== 'confirmLeavePage') {
    return null;
  }

  return (
    <Modal
      width="650px"
      primaryBtnLabel="Non"
      secondaryBtnLabel="Oui"
      onClose={appUiStore.closeModal}
      onSecondaryBtnClick={() => {
        appUiStore.unblockHistory();
        if (appUiStore.modal?.params) {
          history.push(appUiStore.modal?.params.nextPage);
        }
      }}
      isAnswerMandatory={true}
    >
      <h3 className="modal__body__title">
        Êtes-vous sûr de vouloir quitter le parcours de paiement ?
      </h3>
    </Modal>
  );
}

export default observer(LeavePageModal);
