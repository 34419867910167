import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import cls from 'classnames';
import Button from 'components/ui/Button/Button';
import './Error.scss';
import { useHistory } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { useEffect, useMemo } from 'react';
import useRootStore from 'hooks/useRootStore';
import { WORDINGS } from 'constants/app';

function Error() {
  const history = useHistory();
  const rootStore = useRootStore();
  const { paymentStore } = rootStore;

  useEffect(() => {
    if (!paymentStore.error) {
      rootStore.clear();
    }
  }, [rootStore, paymentStore.error]);

  const { TITLE, BODY, CTA } = useMemo(() => {
    if (paymentStore.error) {
      return WORDINGS.PAYMENT_ERROR_MODAL;
    } else {
      return WORDINGS.ERROR_MODAL;
    }
  }, [paymentStore.error]);

  return (
    <section
      className={cls('error', 'container', {
        'error--payment': paymentStore.error,
      })}
    >
      <div className="error__icon-wrapper">
        <FontAwesomeIcon icon={faTimes} className="error__icon" />
      </div>
      {paymentStore.error && !!paymentStore.transactionNumber && (
        <div
          data-testid="error-transaction-number"
          className="recap__sub-title"
        >
          Transaction n°{' '}
          <span className="number">{paymentStore.transactionNumber}</span>
        </div>
      )}
      <div data-testid="error-title" className="error__text">
        {TITLE}
      </div>
      <div className="error__text">{BODY}</div>
      <Button
        className="error__btn"
        primary
        onClick={() => {
          if (paymentStore.error) {
            history.push(ROUTES.invoice);
          } else {
            history.push(ROUTES.home);
          }
        }}
      >
        {CTA}
      </Button>
    </section>
  );
}

export default Error;
