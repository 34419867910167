import { HTMLAttributes, ReactElement } from 'react';
import cn from 'classnames';
import './Card.scss';

interface Props extends HTMLAttributes<HTMLElement> {
  children?: string | ReactElement | ReactElement[];
}

export default function Card({ children, className, ...props }: Props) {
  return <div className={cn('card', className)} {...props}>{children}</div>;
}
