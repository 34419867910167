import Button from 'components/ui/Button/Button';
import Loading from 'components/ui/Loading/Loading';
import { useEffect, useRef, useState } from 'react';

export default function InitPayment() {
  const [sipsParameters, setSipsParameters] = useState<SipsParameters | null>(
    null
  );
  const [displayTestTools, setDisplayTestTools] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    window.parent.postMessage({ messageType: 'payment-iframe-loaded' }, '*');
    window.addEventListener('message', (evt) => {
      if (evt.data?.messageType === 'set-payment-params') {
        setSipsParameters(evt.data?.sipsParameters);
      } else if (evt.data?.messageType === 'display-test-tools') {
        setDisplayTestTools(true);
      }
    });
  }, []);

  useEffect(() => {
    if (sipsParameters && formRef.current) {
      formRef.current.submit();
    }
  }, [sipsParameters]);

  function handleSimulateSuccess() {
    window.parent.postMessage(
      {
        messageType: 'payment-result',
        state: 'success',
        transactionNumber: 'DEV1234',
      },
      '*'
    );
  }

  function handleSimulateAbort() {
    window.parent.postMessage(
      {
        messageType: 'payment-result',
        state: 'abandonment',
        transactionNumber: 'DEV1234',
      },
      '*'
    );
  }

  function handleSimulateError() {
    window.parent.postMessage(
      {
        messageType: 'payment-result',
        state: 'failure',
        transactionNumber: 'DEV1234',
      },
      '*'
    );
  }

  return (
    <>
      {!displayTestTools && <Loading />}
      {sipsParameters && (
        <form
          ref={formRef}
          method="post"
          action={sipsParameters.redirectionURL}
        >
          <input
            type="hidden"
            name="redirectionVersion"
            value={sipsParameters.redirectionVersion}
          />
          <input
            type="hidden"
            name="redirectionData"
            value={sipsParameters.redirectionData}
          />
        </form>
      )}
      {displayTestTools && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Button onClick={handleSimulateSuccess}>Payment Success</Button>
          <Button onClick={handleSimulateAbort}>Payment Aborted</Button>
          <Button onClick={handleSimulateError}>Payment Error</Button>
        </div>
      )}
    </>
  );
}
