import RootStore from './RootStore';
import { action, makeObservable, observable, runInAction } from 'mobx';
import CustomerConsentService from 'services/CustomerConsentService';
import { mapCurrentConsentData } from 'utils/data-mappers';

export default class CustomerConsentStore {
  rootStore: RootStore;
  customerConsentService: CustomerConsentService;
  // observables
  currentConsent: CurrentConsentEntity | null = null;
  error = false;
  isLoading = false;

  constructor(root: RootStore, customerConsentService: CustomerConsentService) {
    makeObservable(this, {
      currentConsent: observable,
      error: observable,
      isLoading: observable,
      setIsLoading: action.bound,
      fetchCurrentConsent: action.bound,
    });
    this.rootStore = root;
    this.customerConsentService = customerConsentService;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  async fetchCurrentConsent() {
    this.setIsLoading(true);
    try {
      const response = await this.customerConsentService.getCurrentConsent();
      runInAction(() => {
        if (response.data) {
          this.currentConsent = mapCurrentConsentData(response.data);
        }
      });
    } catch (err) {
      if (err !== 'session') {
        runInAction(() => {
          this.error = true;
        });
      }
    } finally {
      runInAction(() => {
        this.setIsLoading(false);
      });
    }
  }
}
