import axios from 'axios';
import { WORDINGS } from 'constants/app';
import RootStore from 'stores/RootStore';

export default function setupAxios(rootStore: RootStore) {
  const { authStore, appUiStore } = rootStore;
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.request.use((config) => {
    if (authStore.accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${authStore.accessToken}`,
      };
    }
    return config;
  });

  axios.interceptors.response.use(undefined, (error) => {
    if (
      401 === error.response.status &&
      error.config.url.indexOf('/auth') === -1
    ) {
      appUiStore.unblockHistory();
      authStore.setErrorMessage(WORDINGS.ERROR.SESSION_EXPIRED);
      rootStore.history?.replace('/');
      return Promise.reject('session');
    }
    return Promise.reject(error);
  });
}
