export function mapInvoiceData(apiInvoice: ApiInvoiceItem): InvoiceEntity {
  return {
    invoiceId: apiInvoice.id,
    invoiceNumber: apiInvoice.number,
    status: apiInvoice.status,
    issueDate: apiInvoice.issue_date,
    dueDate: apiInvoice.due_date,
    amount: apiInvoice.amount,
    dunningCount: apiInvoice.dunning_count,
    customer: mapCustomerData(apiInvoice.customer),
    company: mapCompanyData(apiInvoice.company),
    transport: mapTransportData(apiInvoice.transport),
    origin: apiInvoice.origin,
  };
}

export function mapCompanyData(apiCompany: ApiCompanyItem): CompanyEntity {
  return {
    id: apiCompany.id,
    name: apiCompany.name,
    email: apiCompany.email,
    phoneNumber: apiCompany.phone_number,
    bic: apiCompany.bank_account.bic,
    iban: apiCompany.bank_account.iban,
    address: apiCompany.legal_status.address,
    equity: apiCompany.legal_status.equity,
    rcsNumber: apiCompany.legal_status.rcs_number,
    representativeJobTitle: apiCompany.legal_status.representative_job_title,
    representativeName: apiCompany.legal_status.representative_name,
    status: apiCompany.legal_status.status,
  };
}

export function mapCustomerData(apiCustomer: ApiCustomerItem): CustomerEntity {
  return {
    id: apiCustomer.id,
    title: apiCustomer.title,
    firstName: apiCustomer.first_name,
    lastName: apiCustomer.last_name,
  };
}

export function mapTransportData(
  apiTransport: ApiTransportItem
): TransportEntity {
  return {
    id: apiTransport.id,
    startDate: apiTransport.start_date,
    endDate: apiTransport.end_date,
    transportType: apiTransport.transport_type,
  };
}

export function mapCustomerContactData(
  contact: ApiCustomerContact
): CustomerContactEntity {
  return {
    hasAnEmail: contact.has_an_email,
  };
}

export function mapCurrentConsentData(
  contact: ApiCurrentConsent
): CurrentConsentEntity {
  return {
    currentVersion: contact.current_version,
  };
}
