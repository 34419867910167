import Loading from 'components/ui/Loading/Loading';
import ROUTES from 'constants/routes';
import useRootStore from 'hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

function RedirectToInvoice() {
  const history = useHistory();
  const { redirect_token } = useParams<RouterParams>();
  const { authStore } = useRootStore();

  useEffect(() => {
    if (redirect_token !== undefined) {
      const isTokenCorrectLength = /^[a-zA-Z\d]{26}$/.test(redirect_token);

      const isTokenFromPAB = redirect_token
        .toLocaleLowerCase()
        .startsWith('pab');

      const isTokenFormatValid = isTokenCorrectLength || isTokenFromPAB;

      if (isTokenFormatValid) {
        authStore.authenticateWithRedirectToken(redirect_token);
        return;
      }
    }
    history.push(ROUTES.error);
  }, [history, authStore, redirect_token]);

  return <Loading />;
}

export default observer(RedirectToInvoice);
