import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// library
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
// project import
import setupAxios from 'setupAxios';
import App from 'components/layout/App/App';
import RootStore from 'stores/RootStore';
import RootStoreContext from 'stores/RootStoreContext';
import { APP_LANGUAGES } from 'constants/app';
import i18n from './core/i18n';

import './index.scss';

const rootStore = new RootStore();

setupAxios(rootStore);

moment.locale(APP_LANGUAGES.FR);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <I18nextProvider i18n={i18n}>
        <RootStoreContext.Provider value={rootStore}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </RootStoreContext.Provider>
      </I18nextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
