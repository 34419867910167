import axios, { AxiosResponse } from 'axios';

interface InvoiceFormData {
  invoice_number: string;
  customer_last_name: string;
  company_id: string;
}

interface InvoiceAccessData {
  access_token: string;
}

export default class AuthService {
  getAuthToken(formData: InvoiceFormData): Promise<AxiosResponse<ApiAuthToken>> {
    return axios.post('/v1/tokens/auth/invoice-form', {
      ...formData
    });
  }

  getRedirectToken(invoiceData: InvoiceAccessData): Promise<AxiosResponse<ApiAuthToken>> {
    return axios.post('/v1/tokens/auth/invoice-access', {
      ...invoiceData
    });
  }
}
