import { IS_PROD } from 'constants/app';
import ROUTES from 'constants/routes';
import { History } from 'history';
import useConfirmLeavePage from 'hooks/useConfirmLeavePage';
import useRootStore from 'hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PaymentStore from 'stores/PaymentStore';
import './Payment.scss';
import { useTranslation } from 'react-i18next';

const handleMessageFromIframe = (
  paymentStore: PaymentStore,
  history: History,
  setIframeLoaded: (loaded: boolean) => void
) => {
  return (evt: any) => {
    if (evt.data?.messageType === 'payment-iframe-loaded') {
      setIframeLoaded(true);
    }
    if (evt.data?.messageType === 'payment-result') {
      switch (evt.data?.state) {
        case 'success':
          paymentStore.setTransactionNumber(evt.data?.transactionNumber);
          history.push(ROUTES.recap);
          break;
        case 'abandonment':
          history.push(ROUTES.invoice);
          break;
        default:
          paymentStore.setError(true);
          if (evt.data?.transactionNumber) {
            paymentStore.setTransactionNumber(evt.data?.transactionNumber);
          }
          history.push(ROUTES.error);
          break;
      }
    }
  };
};

function Payment() {
  const history: any = useHistory();

  const { invoiceStore, paymentStore } = useRootStore();

  const { t, i18n } = useTranslation();

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [displayTestTools, setDisplayTestTools] = useState(false);

  useConfirmLeavePage();

  useEffect(() => {
    const listener = handleMessageFromIframe(
      paymentStore,
      history,
      setIframeLoaded
    );

    window.addEventListener('message', listener);

    return () => {
      paymentStore.clearSipsParameters();
      window.removeEventListener('message', listener);
    };
  }, [history, paymentStore]);

  useEffect(() => {
    if (IS_PROD) {
      paymentStore.initPayment({
        invoiceId: invoiceStore.invoiceId,
        language: i18n.language as Language,
      });
    } else {
      setDisplayTestTools(true);
    }
  }, [paymentStore, invoiceStore.invoiceId, i18n.language]);

  useEffect(() => {
    if (iframeLoaded && paymentStore.sipsParameters) {
      iframeRef.current?.contentWindow?.postMessage(
        {
          messageType: 'set-payment-params',
          sipsParameters: { ...paymentStore.sipsParameters },
        },
        '*'
      );
    }
  }, [iframeLoaded, paymentStore.sipsParameters, paymentStore, history]);

  useEffect(() => {
    if (iframeLoaded && displayTestTools) {
      iframeRef.current?.contentWindow?.postMessage(
        {
          messageType: 'display-test-tools',
        },
        '*'
      );
    }
  }, [iframeLoaded, displayTestTools]);

  return (
    <section className="payment container">
      <div className="page-title">
        {t('body.payment-title')}
        {/* Renseignez les informations de votre carte */}
      </div>
      <div className="payment__body">
        <div className="payment__iframe-wrapper">
          <iframe
            key={i18n.language}
            ref={iframeRef}
            className="payment__iframe"
            title="Paiement iFrame"
            src={ROUTES.initPayment}
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default observer(Payment);
