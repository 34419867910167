import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { IS_PROD } from 'constants/app';
import Header from 'components/layout/Header/Header';
import Footer from 'components/layout/Footer/Footer';
import Landing from 'components/screens/Landing/Landing';
import Invoice from 'components/screens/Invoice/Invoice';
import Payment from 'components/screens/Payment/Payment';
import Error from 'components/screens/Error/Error';
import ROUTES, { NO_LAYOUT_ROUTES } from 'constants/routes';
import useRootStore from 'hooks/useRootStore';
import LegalNotice from 'components/screens/LegalNotice/LegalNotice';
import Recap from 'components/screens/Recap/Recap';
import RedirectToInvoice from 'components/screens/RedirectToInvoice/RedirectToInvoice';
import LeavePageModal from 'components/modals/LeavePageModal';
import InitPayment from 'components/screens/InitPayment/InitPayment';
import PaymentResult from 'components/screens/PaymentResult/PaymentResult';

import './App.scss';
import { useAppTranslate } from 'hooks/useAppTranstate';

function App() {
  const history = useHistory();
  const rootStore = useRootStore();
  const { pathname } = useLocation();
  const enableTranslate = useAppTranslate();
  const noLayout = useMemo(
    () => NO_LAYOUT_ROUTES.includes(pathname),
    [pathname]
  );

  useEffect(() => {
    rootStore.setHistory(history);
  }, [history, rootStore]);

  // Scroll top when navigate
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  return (
    <div className="app">
      <Helmet>
        {IS_PROD && !noLayout && (
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="8de56972-9d06-4c67-b260-1310a2875a38"
            data-blockingmode="auto"
            type="text/javascript"
          ></script>
        )}
      </Helmet>
      {!noLayout && <Header enableTranslate={enableTranslate} />}
      <section className="body">
        <Switch>
          <Route path={ROUTES.home} exact component={Landing} />
          <Route path={ROUTES.invoice} exact component={Invoice} />
          <Route path={ROUTES.payment} exact component={Payment} />
          <Route path={ROUTES.legalNotice} exact component={LegalNotice} />
          <Route path={ROUTES.recap} exact component={Recap} />
          <Route path={ROUTES.error} exact component={Error} />
          <Route path={ROUTES.initPayment} exact component={InitPayment} />
          <Route path={ROUTES.paymentResult} exact component={PaymentResult} />
          <Route
            path={ROUTES.redirectToInvoice}
            exact
            component={RedirectToInvoice}
          />
        </Switch>
      </section>
      {!noLayout && <Footer />}
      <LeavePageModal />
    </div>
  );
}

export default observer(App);
