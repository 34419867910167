import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import './Tooltip.scss';

type TooltipDirection = 'right' | 'left' | 'top' | 'bottom';

interface Props extends HTMLAttributes<HTMLElement> {
  label?: string;
  direction?: TooltipDirection;
}

const CONTENT_MARGIN = 10;
const DELAY_DURATION = 0.3;

export default function Tooltip({ className, ...props }: Props) {
  const [isHover, setIsHover] = useState(false);
  const [direction, setDirection] = useState(props.direction);
  const [, setOffsetTop] = useState(0);

  // Check if have available space at left and top side
  const [isCheckedRightSide, setIsCheckedRightSide] = useState(false);
  const [isCheckedTopSide, setIsCheckedTopSide] = useState(false);

  // Label and content contanst
  const contentRef = useRef<HTMLDivElement>(null);
  const contentRect = contentRef.current?.getBoundingClientRect();
  const labelRef = useRef<HTMLElement>(null);
  const labelRect = labelRef.current?.getBoundingClientRect();
  const labelOffsetTop = labelRef.current?.offsetTop;

  const body = document.body,
    html = document.documentElement;
  const bodyHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  const bodyWidth = Math.max(
    body.scrollWidth,
    body.offsetWidth,
    html.clientWidth,
    html.scrollWidth,
    html.offsetWidth
  );

  // Update content's position when scrolling
  useEffect(() => {
    const scrollHandler = () => {
      setOffsetTop(window.pageYOffset);
    };
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  // Calculate content's position
  const getContentPosition = useCallback(
    (
      labelRect: DOMRect | undefined,
      contentRect: DOMRect | undefined,
      contentPosition: string = 'right'
    ) => {
      if (labelRect && contentRect) {
        let left = 0;
        let top = 0;
        let transform = '';

        const widthContent = contentRect.width + CONTENT_MARGIN;
        const heightContent = contentRect.height + CONTENT_MARGIN;

        // Position right and left
        // set other direction when don't have enough space
        if (contentPosition === 'right' || contentPosition === 'left') {
          top = labelRect?.top + labelRect.height / 2;

          if (labelOffsetTop) {
            // - Check height
            // + Top side
            const heightTopAvailable = labelOffsetTop;
            if (heightTopAvailable <= heightContent / 2) {
              setDirection('bottom');

              return;
            }

            // + Bottom side
            const heightBottomAvailable =
              bodyHeight - (labelOffsetTop + labelRect?.height);
            if (heightBottomAvailable <= contentRect.height / 2) {
              setDirection('top');

              return;
            }
          }
        }

        if (contentPosition === 'right') {
          // set other direction when don't have enough space
          const widthAvailable = bodyWidth - labelRect?.right;
          if (widthAvailable <= widthContent) {
            if (isCheckedRightSide) {
              setDirection('bottom');
            } else {
              setDirection('left');
            }
            setIsCheckedRightSide(true);
            return;
          }

          left = labelRect?.right;
          transform = `translate(${CONTENT_MARGIN}px, -50%)`;
        }

        if (contentPosition === 'left') {
          // set other direction when don't have enough space
          const widthAvailable = labelRect?.left;
          if (widthAvailable <= widthContent) {
            if (isCheckedRightSide) {
              setDirection('bottom');
            } else {
              setDirection('right');
            }
            return;
          }

          left = labelRect?.left - widthContent;
          transform = `translate(0, -50%)`;
        }

        // Position top and bottom
        // set other direction when don't have enough space
        if (contentPosition === 'top' || contentPosition === 'bottom') {
          left = labelRect.left + labelRect?.width / 2;

          // - Check width
          // + Left side
          const widthLeftAvailable = labelRect?.left;
          if (widthLeftAvailable <= widthContent / 2) {
            setDirection('right');

            return;
          }

          // + Right side
          const widthRightAvailable = bodyWidth - labelRect?.right;
          if (widthRightAvailable <= widthContent / 2) {
            setDirection('left');

            return;
          }
        }

        if (contentPosition === 'top') {
          // set other direction when don't have enough space
          if (labelOffsetTop) {
            const heightAvailable = labelOffsetTop;
            if (heightAvailable <= heightContent) {
              if (isCheckedTopSide) {
                setDirection('right');
              } else {
                setDirection('bottom');
              }

              setIsCheckedTopSide(true);
              return;
            }
          }

          top = labelRect?.top - heightContent;
          transform = `translate(-50%, 0)`;
        }
        if (contentPosition === 'bottom') {
          // set other direction when don't have enough space
          if (labelOffsetTop) {
            const heightAvailable = bodyHeight - labelOffsetTop;
            if (heightAvailable <= heightContent) {
              if (isCheckedTopSide) {
                setDirection('right');
              } else {
                setDirection('top');
              }

              return;
            }
          }

          top = labelRect?.bottom;
          transform = `translate(-50%, ${CONTENT_MARGIN}px)`;
        }

        return {
          left,
          top,
          transform,
        };
      }
    },
    [
      bodyHeight,
      bodyWidth,
      isCheckedRightSide,
      isCheckedTopSide,
      labelOffsetTop,
    ]
  );

  const contentStyle = useMemo(() => {
    return {
      pointerEvents: isHover ? ('inherit' as 'inherit') : ('none' as 'none'),
      ...getContentPosition(labelRect, contentRect, direction),
    };
  }, [direction, isHover, contentRect, labelRect, getContentPosition]);

  const tooltipWrapperStyle = useMemo(
    () => ({
      opacity: isHover ? 1 : 0,
      transition: `opacity ${DELAY_DURATION}s ease`,
    }),
    [isHover]
  );

  return (
    <div className={cn('tooltip', className)}>
      <span
        ref={labelRef}
        className="tooltip__label"
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
      >
        <span className="tooltip__label__inner">{props.label}</span>
      </span>
      <div style={tooltipWrapperStyle} className="tooltip__content-wrapper">
        <div
          ref={contentRef}
          style={contentStyle}
          className={cn('tooltip__content', `tooltip__content--${direction}`)}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
