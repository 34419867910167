// library
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// project import
import { APP_LANGUAGES } from 'constants/app';
import en from './en.json';
import fr from './fr.json';

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: APP_LANGUAGES.FR,
    fallbackLng: APP_LANGUAGES.FR,

    interpolation: {
      escapeValue: true, // react already safes from xss
    },
  });

export default i18n;
