import axios, { AxiosResponse } from 'axios';

export default class PaymentService {
  initPayment({
    invoiceId,
    language,
  }: ApiPaymentInitParams): Promise<AxiosResponse<ApiPaymentInitResult>> {
    return axios.post(`/v1/payments/${invoiceId}?language=${language}`);
  }

  sendEmailConfirmation(invoiceId: string): Promise<AxiosResponse<null>> {
    return axios.post(`/v1/payments/${invoiceId}/last/notify-confirmation`);
  }
}
