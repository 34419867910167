import RootStore from './RootStore';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { saveAs } from 'file-saver';
import InvoicesService from 'services/InvoicesService';
import { mapInvoiceData } from 'utils/data-mappers';

export default class InvoiceStore {
  rootStore: RootStore;
  invoicesService: InvoicesService;

  // observable values
  invoiceId: string = '';
  userName: string = '';
  invoiceNumber: string = '';
  companyId: string = '';
  invoiceData: null | InvoiceEntity = null;
  error = false;
  isLoading = false;
  hasDocumentDownloadError = false;

  constructor(root: RootStore, invoicesService: InvoicesService) {
    this.rootStore = root;
    this.invoicesService = invoicesService;

    makeObservable(this, {
      invoiceId: observable,
      userName: observable,
      invoiceNumber: observable,
      companyId: observable,
      error: observable,
      invoiceData: observable,
      isLoading: observable,
      hasDocumentDownloadError: observable,
      setCompanyId: action.bound,
      setUserName: action.bound,
      setInvoiceId: action.bound,
      setInvoiceNumber: action.bound,
      setInvoiceData: action.bound,
      fetchInvoiceData: action.bound,
      downloadInvoiceDocument: action.bound,
      clear: action.bound,
      setIsLoading: action.bound,
      setError: action.bound,
    });
  }

  setInvoiceId(id: string) {
    this.invoiceId = id;
  }

  setUserName(userName: string) {
    this.userName = userName;
  }

  setInvoiceNumber(invoiceNumber: string) {
    this.invoiceNumber = invoiceNumber;
  }

  setCompanyId(companyId: string) {
    this.companyId = companyId;
  }

  setInvoiceData(data: InvoiceEntity) {
    this.invoiceData = data;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setError(error: boolean) {
    this.error = error;
  }

  async fetchInvoiceData() {
    this.setIsLoading(true);
    if (!this.invoiceId) {
      this.setError(true);
      return;
    }
    try {
      const response = await this.invoicesService.getInvoiceById(
        this.invoiceId
      );
      runInAction(() => {
        const invoiceDataMapper = mapInvoiceData(response.data);
        const company = invoiceDataMapper.company;
        this.rootStore.globalStorage.setValue('company', company);
        this.setInvoiceNumber(invoiceDataMapper.invoiceNumber);
        this.setInvoiceData(invoiceDataMapper);
        this.setIsLoading(false);
      });
    } catch (error) {
      if (error !== 'session') {
        this.setError(true);
      }
    } finally {
      runInAction(() => {
        this.setIsLoading(false);
      });
    }
  }

  async downloadInvoiceDocument() {
    this.setIsLoading(true);

    try {
      const response = await this.invoicesService.getInvoiceDocumentById(
        this.invoiceId
      );
      const contentType = response.headers['content-type'];
      const filename =
        response.headers['content-disposition']
          ?.split(';')
          .find((disposition: string) => disposition.includes('filename='))
          ?.replace('filename=', '')
          .trim() ?? 'invoice-document.pdf';

      const fileContent = new Blob([response.data], { type: contentType });
      saveAs(fileContent, filename);
    } catch (error) {
      if (error !== 'session') {
        runInAction(() => {
          this.hasDocumentDownloadError = true;
        });
      }
    } finally {
      runInAction(() => {
        this.setIsLoading(false);
      });
    }
  }

  clear() {
    this.invoiceId = '';
    this.userName = '';
    this.invoiceNumber = '';
    this.companyId = '';
    this.invoiceData = null;
    this.error = false;
  }
}
