import axios, { AxiosResponse } from 'axios';

export default class CustomerContactService {
  getCustomerContact(id: string): Promise<AxiosResponse<ApiCustomerContact>> {
    return axios(`/v1/customers/${id}/contact`);
  }

  updateCustomerContact(
    id: string,
    data: ApiCustomerContactUpdated
  ): Promise<AxiosResponse<ApiCustomerContactUpdated>> {
    return axios.put(`/v1/customers/${id}/contact`, data);
  }
}
