import RootStore from './RootStore';
import { action, makeObservable, observable } from 'mobx';
import PaymentService from 'services/PaymentService';
import ROUTES from 'constants/routes';

export default class PaymentStore {
  rootStore: RootStore;
  paymentService: PaymentService;
  sipsParameters: SipsParameters | null = null;
  transactionNumber: string = '';
  error: boolean = false;

  constructor(root: RootStore, paymentService: PaymentService) {
    this.rootStore = root;
    this.paymentService = paymentService;
    makeObservable(this, {
      error: observable,
      sipsParameters: observable,
      transactionNumber: observable,
      initPayment: action.bound,
      setTransactionNumber: action.bound,
      setError: action.bound,
      clearSipsParameters: action.bound,
      setSipsParameters: action.bound,
    });
  }

  async initPayment(params: ApiPaymentInitParams) {
    try {
      const response = await this.paymentService.initPayment(params);
      const { data } = response;
      this.setSipsParameters({
        redirectionData: data.redirection_data,
        redirectionURL: data.redirection_url,
        redirectionVersion: data.redirection_version,
      });
    } catch (err) {
      if (err !== 'session') {
        this.rootStore.history?.push(ROUTES.error);
      }
    }
  }

  setSipsParameters(sipsParameters: SipsParameters) {
    this.sipsParameters = sipsParameters;
  }

  setTransactionNumber(transactionNumber: string) {
    this.transactionNumber = transactionNumber;
  }

  setError(isError: boolean) {
    this.error = isError;
  }

  clearSipsParameters() {
    this.sipsParameters = null;
  }
}
