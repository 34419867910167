import { useEffect, useState } from 'react';
// library
import { useTranslation } from 'react-i18next';
// project import
import { APP_LANGUAGES } from 'constants/app';

const enableTranslatePagePaths = ['/invoice', '/payment', '/recap'];

const checkEnableTranslate = (pathToCheck: string) => {
  let enable = false;

  enableTranslatePagePaths.forEach((path) => {
    if (pathToCheck.includes(path)) {
      enable = true;

      return;
    }
  });

  return enable;
};

const getAppLanguageDefault = () => {
  const isBrowserLangFr = ['fr', 'fr-FR'].includes(navigator.language);

  return isBrowserLangFr ? APP_LANGUAGES.FR : APP_LANGUAGES.EN;
};

export const useAppTranslate = () => {
  const { i18n } = useTranslation();

  const [enable, setEnable] = useState(false);

  useEffect(() => {
    const isTranslate = checkEnableTranslate(window.location.href);

    setEnable(isTranslate);

    if (!isTranslate) {
      i18n.changeLanguage(APP_LANGUAGES.FR);
    } else {
      // Set app language by browser language
      const browserLanguage = getAppLanguageDefault();

      i18n.changeLanguage(browserLanguage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, i18n]);

  return enable;
};
