import cn from 'classnames';
import { FormHTMLAttributes, ReactElement } from 'react';
import './Form.scss';

interface Props extends FormHTMLAttributes<HTMLFormElement> {
  children: string | ReactElement | ReactElement[] | any;
}

export default function Form({ children, className, ...props }: Props) {
  return <form className={cn('form', className)} {...props}>
    {children}
  </form>
}
