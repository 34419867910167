import { HTMLAttributes, MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import './Modal.scss';
import Button from '../Button/Button';

export interface ModalProps extends HTMLAttributes<HTMLElement> {
  isVisible?: boolean;
  title?: string;
  children?: any;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  onPrimaryBtnClick?: MouseEventHandler<HTMLButtonElement>;
  onSecondaryBtnClick?: MouseEventHandler<HTMLButtonElement>;
  onClose: MouseEventHandler<any>;
  width?: string;
  isAnswerMandatory?: boolean;
}

export default function Modal({
  isVisible = true,
  title,
  children,
  width,
  primaryBtnLabel,
  secondaryBtnLabel,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  onClose,
  isAnswerMandatory = false,
}: ModalProps) {
  return (
    <div data-testid="modal" className={cn('modal', { show: isVisible })}>
      <div className="modal__dialog" style={{ width: width }}>
        <div className="modal__header">
          {title && <div className="modal__header__title">{title}</div>}
          {!isAnswerMandatory && (
            <div className="modal__header__close" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
        {children && <div className="modal__body">{children}</div>}
        <div className="modal__footer">
          {primaryBtnLabel && (
            <Button
              className="button--modal"
              primary
              onClick={(e) => {
                if (onPrimaryBtnClick) {
                  onPrimaryBtnClick(e);
                }
                onClose(e);
              }}
            >
              {primaryBtnLabel}
            </Button>
          )}
          {secondaryBtnLabel && (
            <Button
              className="button--modal"
              secondary
              onClick={(e) => {
                if (onSecondaryBtnClick) {
                  onSecondaryBtnClick(e);
                }
                onClose(e);
              }}
            >
              {secondaryBtnLabel}
            </Button>
          )}
        </div>
      </div>
      <div
        className="modal__overlay"
        data-testid="modal-overlay"
        onClick={(e) => {
          if (!isAnswerMandatory) {
            onClose(e);
          }
        }}
      ></div>
    </div>
  );
}
