import { BrowserHistory } from 'history';
import { STORAGE_KEY_APP, STORAGE_KEY_GLOBAL } from 'constants/app';
import AuthService from 'services/AuthService';
import CompaniesService from 'services/CompaniesService';
import InvoicesService from 'services/InvoicesService';
import StorageService from 'services/StorageService';
import PaymentService from 'services/PaymentService';
import AppUiStore from './AppUiStore';
import AuthStore from './AuthStore';
import CompaniesStore from './CompaniesStore';
import InvoiceStore from './InvoiceStore';
import PaymentStore from './PaymentStore';
import CustomerContactService from 'services/CustomerContactService';
import CustomerContactStore from './CustomerContactStore';
import CustomerConsentStore from './CustomerConsentStore';
import CustomerConsentService from 'services/CustomerConsentService';

export default class RootStore {
  companiesStore: CompaniesStore;
  invoiceStore: InvoiceStore;
  paymentStore: PaymentStore;
  appUiStore: AppUiStore;
  authStore: AuthStore;
  customerContactStore: CustomerContactStore;
  customerConsentStore: CustomerConsentStore;
  storageService: StorageService<AppStorageValues>;
  globalStorage: StorageService<GlobalStorageValue>;
  history?: BrowserHistory;

  constructor() {
    const companiesService = new CompaniesService();
    const invoicesService = new InvoicesService();
    const authService = new AuthService();
    const paymentService = new PaymentService();
    const customerContactService = new CustomerContactService();
    const customerConsentService = new CustomerConsentService();
    this.storageService = new StorageService(sessionStorage, STORAGE_KEY_APP);
    this.globalStorage = new StorageService(localStorage, STORAGE_KEY_GLOBAL);

    this.appUiStore = new AppUiStore(this);
    this.authStore = new AuthStore(this, authService);
    this.companiesStore = new CompaniesStore(this, companiesService);
    this.invoiceStore = new InvoiceStore(this, invoicesService);
    this.paymentStore = new PaymentStore(this, paymentService);
    this.customerContactStore = new CustomerContactStore(
      this,
      customerContactService,
      paymentService
    );
    this.customerConsentStore = new CustomerConsentStore(
      this,
      customerConsentService
    );

    this.loadFromSessionStorage();
  }

  setHistory(history: any) {
    this.history = history;
  }

  navigate(path: string) {
    if (this.history) {
      this.history.push(path);
    }
  }

  clear() {
    this.authStore.clear();
    this.invoiceStore.clear();
    this.globalStorage.clear();
  }

  loadFromSessionStorage() {
    const { invoiceId, accessToken } = this.storageService.getValues();

    if (invoiceId) {
      this.invoiceStore.setInvoiceId(invoiceId);
    }

    if (accessToken) {
      this.authStore.setAccessToken(accessToken);
    }
  }
}
